import { graphql, useStaticQuery } from 'gatsby'

const ReviewData = () => {
    const ReviewQuery = useStaticQuery(graphql`
    query {
        customers: allMarkdownRemark(limit: 3 sort: { order: DESC, fields: [frontmatter___date] }
            filter: {fileAbsolutePath: {regex: "/(testimonials)/.*[.]md$/"}}) {
            edges {
                node {
                    fields {
                        slug
                    }
                    id
                    excerpt(pruneLength: 250)
                    frontmatter {
                        date(formatString: "DD MMMM, YYYY")
                        title
                        customername
                        customerlocation
                    }
                }
            }
        }
        customersfull: allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }
            filter: {fileAbsolutePath: {regex: "/(testimonials)/.*[.]md$/"}}) {
            edges {
                node {
                    fields {
                        slug
                    }
                    id
                    excerpt(pruneLength: 250)
                    frontmatter {
                        date(formatString: "DD MMMM, YYYY")
                        title
                        customername
                        customerlocation
                    }
                }
            }
        }
        customersettings: file(relativePath: {eq: "settings/settings-customers.md"}) {
            id
            childMarkdownRemark {
              frontmatter {
                lblcustomers
                seotitle
                seometadesc
                featureimage {
                    childImageSharp {
                        fluid(maxWidth: 1536, srcSetBreakpoints: [640, 768, 1024, 1280]) {
                            ...GatsbyImageSharpFluid_withWebp 
                        }
                    }
                  publicURL
                  extension
                }
                heading
                title
                featureimagealt
              }
            }
          }
    }
    `)
    return ReviewQuery
}

export default ReviewData