import React from 'react';
import Card from './CardTwo';
import { Link } from "gatsby"
// import Button from "./Button"

const CustomerCard = ({ customer }) => (
  <Card className="mb-8 transition duration-500 ease-in-out transform hover:scale-98">
    <Link to={customer.fields.slug} itemProp="url">
    <p className="text-xl font-semibold text-center">{customer.frontmatter.title}</p>
    <p className="mt-6 text-center">{customer.excerpt}</p>
    {/* <div className="flex items-center mt-8"> */}
      <div className="text-center">
        <p>{customer.frontmatter.customername}</p>
        <p className="text-sm">{customer.frontmatter.customerlocation}</p>
      </div>
    {/* </div> */}
    {/* <div className="pt-4 text-center">
          <Link to={customer.fields.slug} itemProp="url">
              <Button>Continue reading...</Button>
          </Link>
      </div> */}
      </Link>
  </Card>
);

export default CustomerCard;
