import React from "react"
import Layout from "../components/layout/Layout"
import HeroImage from "../components/HeroImage"
import SEO from "../components/SEO"
import LabelText from "../components/LabelText"
import CustomerCard from "../components/CustomerCard"
import { graphql, Link } from "gatsby"
import testimonialData from "../data/review-data"
import { BsBoxArrowInLeft, BsBoxArrowInRight } from "react-icons/bs"
import { ImHome } from "react-icons/im"

const TestimonialList = ({data, location, pageContext}) => {
    const { customersettings } = testimonialData()
    const settings = customersettings.childMarkdownRemark
    const testimonialfull = data.testimonialdata
    const { currentPage, testNumPages } = pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === testNumPages
    const prevPage = currentPage - 1 === 1 ? "/testimonials/" : ("/testimonials/"+(currentPage - 1).toString()+"/")
    const nextPage = ("/testimonials/"+(currentPage + 1).toString()+"/")
    return (
        <>
            <Layout location={location}>
                <SEO title={settings.frontmatter.seotitle} description={settings.frontmatter.seometadesc} image={settings.frontmatter.featureimage.publicURL}/>
                <HeroImage markdown={settings} />
                <div className="container mx-auto">
                    <div className="text-left px-4 pt-4 pb-4">
                        <ul className="text-left text-xs md:text-base">
                            <li className="inline-block">
                            <Link to="/">
                                <span className="inline-block pr-2"><ImHome /></span>
                                <span className="px-1">Home</span>
                            </Link>
                            </li>
                            <li className="inline-block"><span>·</span></li>
                            <li className="inline-block">
                            <span className="px-1">{settings.frontmatter.title}</span>
                            </li>
                        </ul>
                    </div>   
                    <LabelText className="mb-8 text-center pt-8">{settings.frontmatter.heading}</LabelText>
                    <div className="flex flex-col md:-mx-3">
                        {testimonialfull.edges.map(edge => {
                            const { fields } = edge.node
                            return (
                                <div key={fields.slug} className="flex-1 px-3">
                                    <CustomerCard customer={edge.node} />
                                </div>
                            )
                        })}
                    </div>
                    <div className="flex flex-row justify-between pt-8 px-12 md:px-12">
                        <div className="text-left">
                            {!isFirst && (
                                <Link to={prevPage} rel="prev"><BsBoxArrowInLeft size={34} className="inline-block" /> Previous Page</Link>
                            )}
                        </div>
                        <div className="text-left">
                            {!isLast && (
                                <Link to={nextPage} rel="prev">Next Page<BsBoxArrowInRight size={34} className="inline-block" /></Link>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default TestimonialList

export const query = graphql`
query($skip: Int!, $limit: Int!) {
    testimonialdata: allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/(testimonials)/.*[.]md$/"}}
        sort: { fields: [frontmatter___date], order: DESC }
        limit: $limit
        skip: $skip
    ) {
        edges {
            node {
                fields {
                    slug
                }
                id
                excerpt(pruneLength: 250)
                frontmatter {
                    date(formatString: "DD MMMM, YYYY")
                    title
                    customername
                    customerlocation
                }
            }
        }
    }
}
`